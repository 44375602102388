@import "../entrypoint_init";

$theme-big-top-primary: hsl(240deg 55% 41%);
$theme-big-top-primary-filter: brightness(0%) saturate(100%) invert(16%) sepia(26%)
  saturate(7258%) hue-rotate(235deg) brightness(97%) contrast(100%);
$theme-big-top-secondary: hsl(347deg 90% 63%);
$theme-big-top-secondary-filter: brightness(0%) saturate(100%) invert(63%) sepia(95%)
  saturate(5143%) hue-rotate(321deg) brightness(101%) contrast(93%);

.theme {
  &.theme-big-top {
    @include theme-variables(
      $theme-big-top-primary,
      $theme-big-top-secondary,
      $theme-big-top-primary-filter,
      $theme-big-top-secondary-filter
    );
    @include theme($theme-big-top-primary, $theme-big-top-secondary);
  }
}

.memorial-theme-option {
  &[data-theme-name="theme-big-top"] {
    @include theme-preview($theme-big-top-primary, $theme-big-top-secondary);
  }
}
